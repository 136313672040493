import { useContext } from "react";
import TrackingContext from "../context/TrackingContext";
import { isBrowser } from "../utils";

const useTaboola = (page) => {
  const { utm_source } = useContext(TrackingContext);

  const setupTaboola = () => {
    const script = document.createElement("script");
    const hoodFunction = document.createElement("script");
    const hoodCode = `window.Hood("utm", "source", "${utm_source}");`;
    hoodFunction.textContent = hoodCode;
    script.src =
      "https://cdn.taboola.com/webpush/publishers/1561934/taboola-push-sdk.js";
    script.async = true;

    script.onload = () => {
      document.body.appendChild(hoodFunction);
    };

    document.head.appendChild(script);
  };

  return {
    setupTaboola:
      isBrowser() && page === 1 && utm_source && !utm_source.includes("fb-")
        ? setupTaboola
        : () => {},
  };
};

export default useTaboola;
